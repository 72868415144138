import { render, staticRenderFns } from "./LiefengTableToggle.vue?vue&type=template&id=2ae8f684&scoped=true&"
import script from "./LiefengTableToggle.vue?vue&type=script&lang=js&"
export * from "./LiefengTableToggle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ae8f684",
  null
  
)

export default component.exports